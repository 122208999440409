<template>
  <div class="brandFiles">
    <el-card>
      <div class="pageBody">
        <div class="handleBoxWrap">
          <el-button size="small" type="primary" @click="addHandle()">新增品牌</el-button>
        </div>
        <el-table v-loading="dataListLoading" :data="dataList" border :height="tabHeight">
          <el-table-column label="序号" type="index"  width="200" header-align="center" align="center">
          </el-table-column>
          <el-table-column v-for="row in headerList" :key="row.key" :prop="row.key" :min-width="row.width" :label="row.label" :show-overflow-tooltip="row.showOverflowTips" :formatter="row.formatter" header-align="center" align="center"></el-table-column>
          <el-table-column label="创建时间" align="center" prop="createDate"></el-table-column>
          <el-table-column label="操作" fixed="right" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editHandle(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="delHandle(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="curPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle"
          >
        </el-pagination>
      </div>
    </el-card>
    <edit-dialog ref="editDialog" @reload="getDataList"></edit-dialog>
  </div>
</template>
<script>
import editDialog from './brandEdit.vue'

function getClientHeight () {
  let clientHeight = 0
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  }
  return clientHeight
}

export default {
  name: 'brand',
  components: { editDialog },
  data () {
    return {
      dataListLoading: false,
      dataList: [],
      curPage: 1,
      total: 0,
      limit: 20,
      headerList: [{
        key: 'name',
        label: '品牌名称'
      }
      ],
      dataForm: {
        name: ''
      },
      tabHeight: '400'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tabHeight = getClientHeight() - 260 + 'px'
    })
  },
  activated () {
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http.get('/brand', {
        params: {
          page: this.curPage,
          limit: this.limit,
          ...this.dataForm
        }
      }).then(({ data: res }) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && data) {
          let { records, current, total } = data
          this.curPage = current * 1
          this.total = total * 1
          this.dataList = records && records.length ? records : []
        }
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.dataListLoading = false
      })
    },
    // 切换每页数量
    pageSizeChangeHandle (size) {
      this.limit = size
      this.curPage = 1
      this.getDataList()
    },
    // 切换页
    pageCurrentChangeHandle (curPage) {
      this.curPage = curPage
      this.getDataList()
    },
    resetSearch () {
      let form = this.$options.data().dataForm
      this.dateRangeList = []
      this.$set(this, 'dataForm', form)
      this.$nextTick(() => {
        this.getDataList()
      })
    },
    // 新增
    addHandle () {
      this.$refs.editDialog.init()
    },
    // 修改
    editHandle (row) {
      console.log(row)
      this.$refs.editDialog.init(row)
    },
    // 删除
    delHandle (id) {
      // console.log(arguments)
      this.$confirm('确定删除该数据吗？', '删除确认').then(() => {
        return this.$http.delete(`/brand/${id}`)
      }).then(({ data: res }) => {
        let { code, msg } = res
        console.log(code, msg)
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.getDataList()
      })
    }
  }
}
</script>
<style lang="scss">
  .brandFiles {
    padding: 12px 20px 0;
    .handleBoxWrap {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .el-select,.el-range-editor.el-input__inner,.el-input{
        margin: 0;
        margin-right: 10px;
        max-width: 260px;
      }
    }
    .treeCursor > * {
      cursor: pointer;
    }
    .el-pagination {
      text-align: right;
      margin-top: 6px;
    }
  }
</style>
